//main.js


$(document).ready(function() {
	$('.mayormodal').magnificPopup({
		type: 'inline',
		preloader: false,
		closeBtnInside: true,
		fixedBgPos: true,
		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in'
	});

	$('.videoToggle').magnificPopup({
		disableOn: 700,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	// $('.overlay').click(function(){
	// 	$(this).removeClass('overlay');
	// });

});
